:root {
  --zIndex-top: 100;
  --zIndex-header: 80;
  --zIndex-menus: 70;

  /* Sizes */
  --sizing-buttonPadding: var(--sizing-small) var(--sizing-xlarge);
  --header-size: 3rem;
  --sidebar-width: 3rem;
}
