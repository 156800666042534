/* Tailwind based naming, see https://tailwindcomponents.com/cheatsheet/ for naming to add more */

.h-full {
  height: 100%;
}

.v-full {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}
