/* Dashbaord graph */
.y-axis .tick line {
  stroke-dasharray: 2 10;
  stroke: var(--color-neutral-6);
}

.y-axis .tick text {
  font-size: 1rem;
}

.x-axis .tick text {
  transform: translate(0, 5px);
  text-transform: uppercase;
  font-size: 1rem;
}

.label {
  fill: var(--color-neutral-7);
  font-size: 1rem;
  cursor: vertical-text;
  transition: fill 250ms;
}
.labelHovered {
  fill: var(--color-primary-1);
}

/* Colors for date pickers */
.react-daterange-picker,
.react-date-picker {
  --color-text: var(--color-text-2);
  --color-input-text: var(--color-text-4);
  --color-input-text-active: var(--color-text-2);
  --color-input-text-divider: var(--color-neutral-7);
  --color-input-background: var(--color-neutral-2);
  --color-border: var(--color-neutral-6);
  --color-border-hover: var(--color-neutral-7);
  --color-clear-button: var(--color-primary-1);
  --color-clear-button-hover: var(--color-primary-2);
  --color-clear-button-disabled: var(--color-primary-4);
  --color-clear-button-stroke: var(--color-neutral-3);
  --color-calendar-button: var(--color-primary-1);
  --color-calendar-button-hover: var(--color-primary-2);
  --color-calendar-button-disabled: var(--color-primary-4);
  --color-button-disabled: var(--color-neutral-6);
  --color-calendar-background: var(--color-neutral-2);
  --color-weekdays: var(--color-text-4);
  --color-weekdays-border: var(--color-neutral-6);
  --color-neighboring-month: var(--color-text-5);
  --color-calendar-shadow: var(--color-dropdown-shadow);
  --color-hover: var(--color-primary-1);
  --color-active: var(--color-neutral-3);
  --color-tile-background: var(--color-neutral-3);
  --color-range-start-or-end: var(--color-primary-1);
  --color-range-start-or-end-text: var(--color-neutral-1);
  --color-disabled: var(--color-text-5);
  --color-nav-arrows: var(--color-text-4);
}

/* React daterange picker */
.react-date-picker,
.react-daterange-picker {
  position: relative;
  font-weight: normal;
}
.react-date-picker__wrapper:hover,
.react-daterange-picker__wrapper:hover {
  border-color: var(--color-border-hover);
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after,
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled,
.react-daterange-picker--disabled {
  --color-input-text: var(--color-text-5);
  --color-calendar-button: var(--color-neutral-7);
  --color-border-hover: var(--color-neutral-6);
}

.react-date-picker__wrapper,
.react-daterange-picker__wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: var(--input-width, 8rem);
  border: thin solid var(--color-border);
  color: var(--color-input-text);
  background-color: var(--color-input-background);
  transition: border-color 250ms, color 250ms;
  height: 2rem;
  padding: var(--sizing-tiny) var(--sizing-small) var(--sizing-tiny)
    var(--sizing-tiny);
  z-index: calc(var(--zIndex-top) - 1);
  border-radius: var(--sizing-tiny);
}

.react-date-picker__inputGroup,
.react-daterange-picker__inputGroup {
  flex-grow: 1;
  display: flex;
  padding: 0 2px;
  align-items: baseline;
  justify-content: left;
  color: var(--color-text-2);
}

.react-date-picker__inputGroup__divider,
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input,
.react-daterange-picker__inputGroup__input {
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  color: inherit;
  -moz-appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input--hasLeadingZero,
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.1em;
}
.react-daterange-picker__inputGroup__year {
  max-width: 4ch;
}

.react-date-picker__button,
.react-daterange-picker__button {
  border: 0;
  background: transparent;
}

.react-date-picker__button:enabled,
.react-daterange-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__calendar,
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-date-picker__calendar--closed,
.react-daterange-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar,
.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}

.react-date-picker--open .react-date-picker__wrapper,
.react-daterange-picker--open .react-daterange-picker__wrapper {
  border-color: var(--color-primary-1);
  color: var(--color-input-text-active);
}

.react-date-picker__clear-button:enabled:hover,
.react-daterange-picker__clear-button:enabled:hover {
  background-color: var(--color-clear-button-hover);
}

.react-date-picker__calendar-button,
.react-daterange-picker__calendar-button {
  margin-left: var(--sizing-small);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* React calendar */
.react-calendar {
  max-width: 100%;
  background: var(--color-calendar-background);
  line-height: 1.125em;
  margin-top: 2px;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: 1px;
}
.react-calendar__navigation::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: var(--sizing-normal);
  left: var(--sizing-normal);
  border-bottom: 1px solid var(--color-weekdays-border);
  border-radius: var(--sizing-tiny);
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover {
  background-color: var(--color-hover);
}
.react-calendar__navigation button[disabled] {
  color: var(--color-button-disabled);
}
.react-calendar__navigation__label {
  font-size: 1.125rem;
  color: inherit;
  text-transform: capitalize;
}
.react-calendar__navigation__arrow {
  font-size: 2.5rem;
  color: var(--color-nav-arrows);
}
.react-calendar__month-view__days__day {
  color: inherit;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize;
  font-size: var(--sizing-font-small);
  color: var(--color-weekdays);
}
.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  color: var(--color-weekdays);
  padding-bottom: var(--sizing-tiny);
  padding-left: var(--sizing-tiny);
  gap: 10px;
  padding-bottom: 7px;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  font-size: var(--sizing-font-small);
  font-weight: normal;
  cursor: auto;
  background-color: transparent;
}

.react-calendar__month-view__days {
  display: grid !important;
  width: 100%;
  grid-template-columns: repeat(7, minmax(0, 2.5rem));
  gap: var(--sizing-tiny);
  row-gap: var(--sizing-tiny);
  padding: var(--sizing-tiny);
}

.react-calendar__tile {
  text-align: center;
  height: 2.5rem;
  width: 2.5rem;
  padding: var(--sizing-small);
  background: none;
  color: var(--color-input-text);
  font-size: var(--sizing-font-normal);
  cursor: pointer;
  border-radius: var(--sizing-tiny);
  font-weight: bold;
  background-color: var(--color-tile-background);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--color-neighboring-month);
  font-weight: normal;
  background-color: transparent;
}

.react-calendar__tile:disabled {
  color: var(--color-disabled);
  cursor: default;
}
.react-calendar__tile:enabled:hover {
  border: 1px solid var(--color-hover);
}

.react-calendar__tile--active {
  background: var(--color-active);
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background-color: var(--color-range-start-or-end);
  color: var(--color-range-start-or-end-text);
}
.react-calendar__tile--rangeStart:enabled:hover,
.react-calendar__tile--rangeStart:enabled:focus,
.react-calendar__tile--rangeEnd:enabled:hover,
.react-calendar__tile--rangeEnd:enabled:focus {
  background: var(--color-hover);
  color: inherit;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: var(--color-hover);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--color-hover);
}
.react-calendar__tile--hoverStart,
.react-calendar__tile--hoverEnd {
  color: inherit;
}

.buttonBackgroundCircle {
  --background-size: 7.5rem;

  padding: var(--sizing-font-xlarge);

  background-color: var(--color-neutral-3);
  border: 0;
  border-radius: 50%;
  color: var(--color-neutral-1);
  font-size: 0.75rem;
  transition: background-color 200ms ease-in;
}

/* react-toastify */
.Toastify__toast {
  padding-left: 1.5rem;
  font-weight: bold;
  border-radius: 0.25rem;
  color: var(--color-neutral-1);
}
.Toastify__toast--error {
  background-color: var(--color-error);
}
.Toastify__toast--success {
  background-color: var(--color-success);
  color: var(--color-neutral-1);
}

.Toastify__close-button {
  color: var(--color-neutral-1);
}

.Toastify__toast-container {
  padding-top: var(--header-size);
}
