* {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
html {
  font-size: 16px;
}

body {
  color: var(--color-text-2);
  background-color: var(--color-neutral-3);
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
body > div:first-child,
main {
  height: 100vh;
  width: 100vw;
}

#portal,
#mobile-button {
  height: 1px;
  width: 1px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

main {
  display: flex;
  flex-direction: column;
}

h2 {
  font-size: var(--sizing-font-normal);
}

h3 {
  font-size: var(--sizing-font-small);
  font-weight: normal;
}

a {
  color: var(--color-primary-1);
  text-decoration: none;
  transition: color 100ms ease-in;
}
a:hover {
  color: var(--color-text-2);
}

/* Form elements don't inherit font settings, setting these properties manually. */
input,
textarea,
button {
  font-family: inherit;
}

/* Scrollbar */
body *::-webkit-scrollbar {
  width: 1rem;
}

body *::-webkit-scrollbar-track {
  background: var(--color-neutral-3);
}

body *::-webkit-scrollbar-thumb {
  background-color: var(--color-neutral-5);
  border-radius: 1rem;
  border: 4px solid var(--color-neutral-3);
}
body *::-webkit-scrollbar-corner {
  background: var(--color-neutral-3);
}

body * {
  scrollbar-width: auto;
  scrollbar-color: var(--color-neutral-5) var(--color-neutral-3);
}

/*
Global helper class to aid accessibility
see https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
*/
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}